:root {
  --fs-sm: clamp(.8rem, .17vi + .76rem, .89rem);
  --fs-base: clamp(1rem, .34vi + .91rem, 1.19rem);
  --fs-md: clamp(1.25rem, .61vi + 1.1rem, 1.58rem);
  --fs-lg: clamp(1.56rem, 1vi + 1.31rem, 2.11rem);
  --fs-xl: clamp(1.95rem, 1.56vi + 1.56rem, 2.81rem);
  --fs-xxl: clamp(2.44rem, 2.38vi + 1.85rem, 3.75rem);
  --fs-xxxl: clamp(3.05rem, 3.54vi + 2.17rem, 5rem);
  --fw-thin: 200;
  --fw-bold: 700;
  --fw-extra-bold: 900;
  --clr-accent-light: #faf0f5;
  --clr-accent: #f0057e;
  --clr-accent-dark: #d70471;
  --clr-white: #fff;
  --clr-black: #2b2b2b;
  --clr-gray-100: #f3f3f3;
  --clr-gray-200: #e0e0e0;
  --clr-gray-300: #d9d9d9;
  --clr-gray-600: #5f5f5f;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  line-height: 1.5;
}

h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

img, picture, svg, video {
  max-width: 100%;
  display: block;
}

p {
  text-wrap: pretty;
  max-width: 75ch;
}

@media (prefers-reduced-motion: no-preference) {
  :has(:target) {
    scroll-behavior: smooth;
    scroll-padding-top: 6rem;
  }
}

body {
  font-family: Roboto, system-ui, sans-serif;
  font-size: var(--fs-base);
  color: var(--clr-black);
  font-display: optional;
}

h1 {
  font-size: var(--fs-md);
  font-weight: var(--fw-bold);
}

h3 {
  font-size: var(--fs-xl);
  font-weight: var(--fw-bold);
}

strong {
  font-weight: var(--fw-bold);
}

.wrapper {
  --max-width: 90rem;
  --padding: 1rem;
  width: min(var(--max-width), 100% - var(--padding) * 2);
  margin-inline: auto;
}

.wrapper-narrow {
  --max-width: 70rem;
}

@media (width >= calc(40em + .001px)) {
  .wrapper {
    --padding: 2rem;
  }
}

.cols-6-5 {
  grid-template-columns: 1fr;
  gap: 3rem;
  display: grid;
}

@media (width >= calc(40em + .001px)) {
  .cols-6-5 {
    grid-template-columns: 6fr 5fr;
    gap: 8.75%;
  }
}

.cols-5-6 {
  grid-template-columns: 1fr;
  gap: 3rem;
  display: grid;
}

@media (width <= 40em) {
  .cols-5-6 > :last-child {
    order: -1;
  }
}

@media (width >= calc(40em + .001px)) {
  .cols-5-6 {
    grid-template-columns: 5fr 6fr;
    gap: 8.75%;
  }
}

.cols-2 {
  gap: 3rem;
  display: grid;
}

@media (width >= calc(40em + .001px)) {
  .cols-2 {
    grid-template-columns: repeat(2, auto);
  }

  .cols-2 > div:last-child {
    text-align: right;
  }
}

.cols-3 {
  gap: 2rem;
  display: grid;
}

@media (width >= calc(40em + .001px)) {
  .cols-3 {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

.vcenter {
  place-items: center;
}

.flow > * + * {
  margin-block-start: 2rem;
}

.mb-regular {
  margin-block: 4rem;
}

@media (width >= calc(40em + .001px)) {
  .mb-regular {
    margin-block: 6rem;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.desktop-only {
  display: none;
}

@media (width >= calc(40em + .001px)) {
  .desktop-only {
    display: initial;
  }
}

.shadow {
  border: 1px solid var(--clr-gray-100);
  border-radius: 1rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
}

.max-height {
  max-height: 30rem;
}

header {
  justify-content: space-between;
  align-items: center;
  padding-block: 2rem;
  display: flex;
}

.logo {
  align-items: center;
  display: flex;
}

.logo img {
  width: 32px;
  height: 29px;
  margin-inline-end: 1rem;
}

.nav-menu {
  gap: 1rem;
  list-style: none;
  display: flex;
}

.nav-menu a {
  color: inherit;
  text-decoration: none;
}

.nav-menu li {
  flex: 1 0 auto;
}

@media (width >= calc(40em + .001px)) {
  .nav-menu {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
  }
}

.menu-button {
  font-weight: var(--fw-bold);
  border-radius: 99vw;
  padding: .5rem 1em;
}

.menu-register {
  background-color: var(--clr-gray-200);
}

.menu-sign-in {
  background-color: var(--clr-gray-100);
}

.menu-sign-in:hover {
  background-color: var(--clr-gray-200);
}

.kicker {
  font-size: var(--fs-base);
  text-transform: uppercase;
  letter-spacing: .04rem;
}

.slogan {
  font-size: var(--fs-xxxl);
  font-weight: var(--fw-bold);
}

.hero-text {
  font-size: var(--fs-md);
}

.hero__image {
  border-radius: 1rem;
  width: 100%;
}

.hero-buttons {
  position: sticky;
  top: 2rem;
}

.hero-cta, .take-a-tour {
  font-size: var(--fs-base);
  font-weight: var(--fw-bold);
  border: 2px solid var(--clr-accent);
  border-radius: 99vw;
  align-items: center;
  gap: .75rem;
  margin-inline-end: 1rem;
  padding: .5em 1.5em;
  text-decoration: none;
  display: inline-flex;
}

.hero-cta {
  background-color: var(--clr-accent);
  color: var(--clr-white);
}

.hero-cta:hover, .hero-cta:focus-visible {
  background-color: var(--clr-accent-dark);
  border: 2px solid var(--clr-accent-dark);
}

.take-a-tour {
  --clr-arrow: var(--clr-black);
  color: var(--clr-black);
  border: 2px solid var(--clr-accent);
}

.take-a-tour:hover, .take-a-tour:focus-visible {
  background-color: var(--clr-accent-light);
}

.cta-buttons {
  background: var(--clr-white);
  padding-block: 1rem;
  position: sticky;
  top: 0;
}

.testimonials {
  background-color: var(--clr-gray-100);
  border-radius: 1rem;
  margin-inline: 2rem;
  padding: 2rem 1rem;
}

.testimonials--header {
  font-weight: var(--fw-thin);
}

.testimonial-list {
  margin-block-start: 3rem;
}

.stars {
  display: flex;
}

.stats {
  font-size: var(--fs-xl);
}

.stats span {
  font-weight: var(--fw-extra-bold);
  font-size: var(--fs-xxl);
}

.testimonial {
  font-style: italic;
}

.testimonial--quote {
  font-size: var(--fs-sm);
  margin-block-start: .5rem;
}

.features {
  gap: 3rem;
  margin-block: 6rem;
  display: grid;
}

.feature h3 {
  margin-block-end: 2rem;
}

.dot-list {
  list-style: none;
}

.dot-list li {
  align-items: baseline;
  margin-block-start: 1rem;
  display: inline-flex;
}

.dot-list li:before {
  content: "";
  background-color: var(--clr-accent);
  border-radius: 50%;
  flex: 1 0 auto;
  width: 1rem;
  height: 1rem;
  margin-inline-end: 1rem;
  display: inline-block;
  transform: translateY(.125rem);
}

.divider {
  width: 100%;
}

.demo {
  max-width: 46rem;
  margin-inline: auto;
}

.demo .take-a-tour {
  margin-block-start: 1rem;
}

footer {
  background-color: var(--clr-gray-300);
  padding-block: 2rem;
}

footer h2 {
  font-size: var(--fs-lg);
  margin-block-end: 1rem;
}

.small {
  font-size: var(--fs-sm);
}

.created-by-list {
  list-style: none;
}

.created-by-list li {
  grid-template-columns: 8rem max-content auto;
  align-items: center;
  gap: .5rem;
  margin-block-end: .5rem;
  display: grid;
}

.created-by-list img {
  width: 1.5rem;
}

.footnote {
  font-size: var(--fs-sm);
  color: var(--clr-gray-600);
  margin-block-start: 2rem;
}

.contact {
  font-size: var(--fs-sm);
  color: var(--clr-black);
  align-items: center;
  gap: .5rem;
  margin-block-start: 2rem;
  text-decoration: none;
  display: inline-flex;
}

.floating-cta {
  --gutter: min(4rem, 5%);
  bottom: var(--gutter);
  right: var(--gutter);
  transform-origin: center;
  transition: transform .25s ease-in-out;
  position: fixed;
}

.floating-cta a {
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}

.floating-cta.hidden {
  transform: scale(0);
}
/*# sourceMappingURL=index.f36a4396.css.map */
