:root {
    --fs-sm: clamp(0.8rem, 0.17vi + 0.76rem, 0.89rem);
    --fs-base: clamp(1rem, 0.34vi + 0.91rem, 1.19rem);
    --fs-md: clamp(1.25rem, 0.61vi + 1.1rem, 1.58rem);
    --fs-lg: clamp(1.56rem, 1vi + 1.31rem, 2.11rem);
    --fs-xl: clamp(1.95rem, 1.56vi + 1.56rem, 2.81rem);
    --fs-xxl: clamp(2.44rem, 2.38vi + 1.85rem, 3.75rem);
    --fs-xxxl: clamp(3.05rem, 3.54vi + 2.17rem, 5rem);

    /*--fs-huge: 4rem;*/
    /*--fs-xxxl: 3rem;*/
    /*--fs-xxl: 2rem;*/
    /*--fs-xl: 1.5rem;*/
    /*--fs-large: 1.25rem;*/
    /*--fs-regular: 1rem;*/
    /*--fs-small: 0.75rem;*/

    --fw-thin: 200;
    --fw-bold: 700;
    --fw-extra-bold: 900;

    --clr-accent-light: hsl(329, 50%, 96%);
    --clr-accent: hsl(329, 96%, 48%);
    --clr-accent-dark: hsl(329, 96%, 43%);

    --clr-white: #FFFFFF;
    --clr-black: #2b2b2b;

    --clr-gray-100: #F3F3F3;
    --clr-gray-200: #E0E0E0;
    --clr-gray-300: #D9D9D9;
    --clr-gray-600: #5F5F5F;
}

/* --- RESET --- */

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    font: inherit;
    /* Get rid of all font sizes and heights */
}

body {
    min-height: 100vh;
    line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
    line-height: 1.1;
}

/* Media responsive */
img,
picture,
svg,
video {
    display: block;
    max-width: 100%;
}

p {
    max-width: 75ch;
    text-wrap: pretty;
    /* Prevents orphans on lines */
}

@media (prefers-reduced-motion: no-preference) {
    :has(:target) {
        scroll-behavior: smooth;
        scroll-padding-top: 6rem;
    }
}


/* GENERAL  */

body {
    font-family: "Roboto", system-ui, sans-serif;
    font-size: var(--fs-base);
    color: var(--clr-black);
    font-display: optional;
}

h1 {
    font-size: var(--fs-md);
    font-weight: var(--fw-bold);
}

h3 {
    font-size: var(--fs-xl);
    font-weight: var(--fw-bold);
}

strong {
    font-weight: var(--fw-bold);
}


/* UTILITIES */

.wrapper {
    --max-width: 90rem;
    --padding: 1rem;

    width: min(var(--max-width), 100% - var(--padding) * 2);
    margin-inline: auto;
}

.wrapper-narrow {
    --max-width: 70rem;
}

@media (width > 40em) {
    .wrapper {
        --padding: 2rem;
    }
}

.cols-6-5 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
}

@media (width > 40em) {
    .cols-6-5 {
        grid-template-columns: 6fr 5fr;
        gap: 8.75%;
    }
}

.cols-5-6 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
}

@media (width <=40em) {
    .cols-5-6 {
        >*:last-child {
            order: -1;
        }
    }
}

@media (width > 40em) {
    .cols-5-6 {
        grid-template-columns: 5fr 6fr;
        gap: 8.75%;
    }
}

.cols-2 {
    display: grid;
    gap: 3rem;
}

@media (width > 40em) {
    .cols-2 {
        grid-template-columns: repeat(2, auto);

        >div:last-child {
            text-align: right;
        }
    }
}

.cols-3 {
    display: grid;
    gap: 2rem;
}

@media (width > 40em) {
    .cols-3 {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}

.vcenter {
    place-items: center;
}

.flow>*+* {
    margin-block-start: 2rem;
}

.mb-regular {
    margin-block: 4rem;
}

@media (width > 40em) {
    .mb-regular {
        margin-block: 6rem;
    }
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.desktop-only {
    display: none;
}

@media (width > 40em) {
    .desktop-only {
        display: initial;
    }
}

.shadow {
    border: 1px solid var(--clr-gray-100);
    border-radius: 1rem;
    box-shadow: 0 0 transparent,
        0 0 transparent,
        0 20px 25px -5px rgba(0, 0, 0, .102),
        0 8px 10px -6px rgba(0, 0, 0, .102);
}

.max-height {
    max-height: 30rem;
}


/* HEADER */

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 2rem;
}

.logo {
    display: flex;
    align-items: center;

    img {
        width: 32px;
        height: 29px;
        margin-inline-end: 1rem;
    }
}

.nav-menu {
    list-style: none;
    display: flex;
    gap: 1rem;

    a {
        text-decoration: none;
        color: inherit;
    }

    li {
        flex: 1 0 auto;
    }
}

@media (width > 40em) {
    .nav-menu {
        flex-direction: row;
        align-items: center;
        gap: 1.5rem;
    }
}

.menu-button {
    font-weight: var(--fw-bold);
    padding: 0.5rem 1em;
    border-radius: 99vw;
}

.menu-register {
    background-color: var(--clr-gray-200);
}

.menu-sign-in {
    background-color: var(--clr-gray-100);

    &:hover {
        background-color: var(--clr-gray-200);
    }
}

/* HERO */

.kicker {
    font-size: var(--fs-base);
    text-transform: uppercase;
    letter-spacing: 0.04rem;
}

.slogan {
    font-size: var(--fs-xxxl);
    font-weight: var(--fw-bold);
}

.hero-text {
    font-size: var(--fs-md);
}

.hero__image {
    width: 100%;
    border-radius: 1rem;
}

.hero-buttons {
    position: sticky;
    top: 2rem;
}

.hero-cta,
.take-a-tour {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    /*text-transform: uppercase;*/
    text-decoration: none;
    font-size: var(--fs-base);
    font-weight: var(--fw-bold);
    padding: 0.5em 1.5em;
    border-radius: 99vw;
    margin-inline-end: 1rem;
    border: 2px solid var(--clr-accent);
}

.hero-cta {
    background-color: var(--clr-accent);
    color: var(--clr-white);
}

.hero-cta:hover,
.hero-cta:focus-visible {
    background-color: var(--clr-accent-dark);
    border: 2px solid var(--clr-accent-dark);
}

.take-a-tour {
    --clr-arrow: var(--clr-black);

    color: var(--clr-black);
    border: 2px solid var(--clr-accent);
}

.take-a-tour:hover,
.take-a-tour:focus-visible {
    background-color: var(--clr-accent-light);
}

.cta-buttons {
    background: var(--clr-white);
    position: sticky;
    top: 0;
    padding-block: 1rem;
}

/* TESTIMONIALS */

.testimonials {
    background-color: var(--clr-gray-100);
    margin-inline: 2rem;
    border-radius: 1rem;
    padding: 2rem 1rem;
}

.testimonials--header {
    font-weight: var(--fw-thin);
}

.testimonial-list {
    margin-block-start: 3rem;
}

.stars {
    display: flex;
}

.stats {
    font-size: var(--fs-xl);

    span {
        font-weight: var(--fw-extra-bold);
        font-size: var(--fs-xxl);
    }
}

.testimonial {
    font-style: italic;
}

.testimonial--quote {
    font-size: var(--fs-sm);
    margin-block-start: 0.5rem;
}

/* FEATURES */

.features {
    display: grid;
    gap: 3rem;
    margin-block: 6rem;
}

.feature h3 {
    margin-block-end: 2rem;
}

.dot-list {
    list-style: none;

    li {
        display: inline-flex;
        align-items: baseline;
        margin-block-start: 1rem;
    }

    li::before {
        content: '';
        display: inline-block;
        flex: 1 0 auto;
        width: 1rem;
        height: 1rem;
        transform: translateY(0.125rem);
        border-radius: 50%;
        background-color: var(--clr-accent);
        margin-inline-end: 1rem;
    }
}

.divider {
    width: 100%;
}


/* DEMO */

.demo {
    margin-inline: auto;
    max-width: 46rem;

    .take-a-tour {
        margin-block-start: 1rem;
    }
}

/* FOOTER */

footer {
    background-color: var(--clr-gray-300);
    padding-block: 2rem;

    h2 {
        font-size: var(--fs-lg);
        margin-block-end: 1rem;
    }
}

.small {
    font-size: var(--fs-sm);
}

.created-by-list {
    list-style: none;

    li {
        margin-block-end: 0.5rem;
        display: grid;
        grid-template-columns: 8rem max-content auto;
        align-items: center;
        gap: 0.5rem;
    }

    img {
        width: 1.5rem;
    }
}

.footnote {
    font-size: var(--fs-sm);
    color: var(--clr-gray-600);
    margin-block-start: 2rem;
}

.contact {
    font-size: var(--fs-sm);
    display: inline-flex;
    align-items: center;
    margin-block-start: 2rem;
    gap: 0.5rem;
    text-decoration: none;
    color: var(--clr-black);
}


/* FLOATING CTA */

.floating-cta {
    --gutter: min(4rem, 5%);

    position: fixed;
    bottom: var(--gutter);
    right: var(--gutter);
    transform-origin: center;
    transition: transform 0.25s ease-in-out;

    a {
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2),
            0 6px 10px 0 rgba(0, 0, 0, .14),
            0 1px 18px 0 rgba(0, 0, 0, .12);
    }

    &.hidden {
        transform: scale(0%);
    }
}